exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-chorder-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/blog/chorder.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-chorder-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-dog-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/blog/dog/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-dog-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-sequencer-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.tsx?__contentFilePath=/opt/build/repo/blog/sequencer.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-tsx-content-file-path-blog-sequencer-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sketches-cardioid-tsx": () => import("./../../../src/pages/sketches/cardioid.tsx" /* webpackChunkName: "component---src-pages-sketches-cardioid-tsx" */),
  "component---src-pages-sketches-clickies-tsx": () => import("./../../../src/pages/sketches/clickies.tsx" /* webpackChunkName: "component---src-pages-sketches-clickies-tsx" */),
  "component---src-pages-sketches-ellipses-tsx": () => import("./../../../src/pages/sketches/ellipses.tsx" /* webpackChunkName: "component---src-pages-sketches-ellipses-tsx" */),
  "component---src-pages-sketches-index-tsx": () => import("./../../../src/pages/sketches/index.tsx" /* webpackChunkName: "component---src-pages-sketches-index-tsx" */),
  "component---src-pages-sketches-radialosc-tsx": () => import("./../../../src/pages/sketches/radialosc.tsx" /* webpackChunkName: "component---src-pages-sketches-radialosc-tsx" */),
  "component---src-pages-sketches-rotateshrink-tsx": () => import("./../../../src/pages/sketches/rotateshrink.tsx" /* webpackChunkName: "component---src-pages-sketches-rotateshrink-tsx" */),
  "component---src-pages-sketches-scratch-tsx": () => import("./../../../src/pages/sketches/scratch.tsx" /* webpackChunkName: "component---src-pages-sketches-scratch-tsx" */),
  "component---src-pages-sketches-spirogrid-tsx": () => import("./../../../src/pages/sketches/spirogrid.tsx" /* webpackChunkName: "component---src-pages-sketches-spirogrid-tsx" */),
  "component---src-pages-sketches-wiggles-tsx": () => import("./../../../src/pages/sketches/wiggles.tsx" /* webpackChunkName: "component---src-pages-sketches-wiggles-tsx" */),
  "component---src-pages-things-chorder-tsx": () => import("./../../../src/pages/things/chorder.tsx" /* webpackChunkName: "component---src-pages-things-chorder-tsx" */),
  "component---src-pages-things-index-tsx": () => import("./../../../src/pages/things/index.tsx" /* webpackChunkName: "component---src-pages-things-index-tsx" */),
  "component---src-pages-things-rtm-tsx": () => import("./../../../src/pages/things/rtm.tsx" /* webpackChunkName: "component---src-pages-things-rtm-tsx" */)
}

